.header {
    background: var(--mantine-color-black);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.header-content {
    width: 100%;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 22px;
    padding-top: 22px;
    box-sizing: border-box;

    @media screen and (max-width: 400px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.burger {
    margin-right: 30px;
}

$header-logo-width: 336px;

.logo-container {
    max-width: 416px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.header-logo {
    background-size: contain;
    background-position-x: left;
    background-position-y: bottom;
    background-repeat: no-repeat;
    height: 100%;
    max-width: $header-logo-width;
    width: $header-logo-width;
}

.navbutton {
    position: relative;
    text-transform: uppercase;
    padding: var(--mantine-spacing-sm);
    padding-bottom: 0;
    font-size: var(--mantine-font-size-md);
    font-weight: 500;
    border-bottom: 2px solid transparent;
    transition:
      border-color 100ms ease,
      color 100ms ease;

    &:hover {
        color: var(--mantine-color-white);
    }

    &-active {
        color: var(--mantine-color-white);
        border-bottom: 2px solid var(--mantine-color-blue-6);
    }
}

.navbutton-container {
    position: relative;
}

.navbutton-page-indicator {
    position: absolute;
    width: 100%;
    background: var(--mantine-color-white);
    height: 3px;
    bottom: -5px;
    left: 0;
    border-radius: 3px;
}

.social-media-links {
    margin: 10px 40px;
    position: absolute;
    top: 0;
    right: 0;
}