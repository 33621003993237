@import './colors.scss';

* {
  margin: 0;
  font-family: 'Noto Sans' !important;
}

body {  
  overflow-x: hidden;
  min-height: 100vh;
}

p {
  text-align: justify;
}

.icon-25x25 {
  width: 25px;
  height: 25px;
}

.logo-hover:hover {
  cursor: pointer;
  opacity: 0.9;
}

// Mantine override
.mantine-AppShell-main {
  width: 100%;
}