.footerContainer {
    background: var(--mantine-color-black);
}

.darkerText {
    color: var(--mantine-color-gray-5);
}

.footer {
    margin-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.googleMapsFrame {
    max-width: 600px;
    width: 100%;
}

.InfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--mantine-color-gray-5);
  
    svg {
      margin-right: 15px;
      min-width: 25px;
      width: 25px;
      height: 25px;

      &:hover {
        opacity: 0.9;
      }
    }

    &__hyperlink {
        color: var(--hyperlink-color-on-dark-background);
    }
}

.viber_whatsapp {
    margin-left: 15px;
}

.socialMedia {
    padding-top: 45px;
}

.copyright {
    margin-top: 50px;
    padding: 0 24px;
}